import React from "react"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core"
import { Constants } from "../../constants"
import useCalendy from "../../hooks/useCalendy"
import { ContentContainer } from "../moleculs/content-container"
import Grid from "@material-ui/core/Grid"
import { Element } from "react-scroll"


function ContactsBlock({ classes, callToActionBlockInfo }) {
  const { title, description, buttonTitle } = callToActionBlockInfo
  const { quoteLink } = Constants
  const { initCalendy } = useCalendy()

  function openForm() {
    window.open(quoteLink, "_blank")
  }

  return (
    <ContentContainer customClass={classes.gradientBg}>
      <Element name={'footerCallToActionBlock'}>
        <div className={classes.content}>
          <Grid
            container
            justifyContent="space-between"
            spacing={6}
          >
            <Grid
              item
              md={6}
              sm={12}
            >
              <Typography variant="h2" className={classes.title}>
                {title}
              </Typography>
              <Typography variant="body1" className={classes.description}>
                {description}
              </Typography>
              <Button className={classes.button} onClick={openForm}>
                {buttonTitle}
              </Button>
            </Grid>
            <Grid
              item
              md={4}
              sm={12}
            >
              <div className={classes.links}>
                <div className={classes.section}>
                  <Typography variant="subtitle" className={classes.sectionTitle}>
                    Have any questions?
                  </Typography>
                  <a
                    href="https://blog.astraloadhq.com/"
                    className={classes.link}
                    target="_blank"
                  >
                    Read our blog
                  </a>
                  <a
                    href="https://t.me/joinchat/DQIDQU7Naz7doknz7u8meQ"
                    className={classes.link}
                    target="_blank"
                  >
                    Join Telegram group
                  </a>
                  <a href={quoteLink} className={classes.link} target="_blank">
                    Get a quote
                  </a>
                </div>
                <div className={classes.section}>
                  <Typography variant="subtitle" className={classes.sectionTitle}>
                    Ready to start working with Astraload?
                  </Typography>
                  <div className={classes.schedule}>
                    <div
                      onClick={initCalendy}
                      className={`${classes.link} ${classes.inline}`}
                    >
                      Schedule a meeting
                    </div>{" "}
                    with Astraload CEO — Evgeny Lukianchikov
                  </div>
                </div>
              </div>

            </Grid>
          </Grid>
        </div>
      </Element>
    </ContentContainer>
  )
}

const styles = theme => ({
  gradientBg: {
    background: "linear-gradient(180deg, #ECF2F6 0%, #FFFFFF 100%)"
  },
  title: {
    marginBottom: "30px",
    whiteSpace: "pre-line",

    [theme.breakpoints.down("xs")]: {
      marginBottom: "20px",
    },
  },
  description: {
    marginBottom: "80px",
    color: theme.palette.text.primary,

    [theme.breakpoints.down("xs")]: {
      marginBottom: "50px",
    },
  },
  button: {
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto 80px",
    },
  },
  links: {
  },
  link: {
    display: "block",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "30px",
    textDecoration: "underline",
    cursor: "pointer",
    color: theme.palette.text.primary,
    letterSpacing: "-0.5px",

    "&:hover": {
      color: theme.palette.text.linkHover,
    },
  },
  inline: {
    display: "inline-block",
  },
  section: {
    color: theme.palette.text.primary,

    "&:not(:last-child)": {
      marginBottom: 50
    }
  },
  sectionTitle: {
    display: "block",
    marginBottom: "20px",
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "38px",
    color: theme.palette.text.primary,
  },
  schedule: {
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "30px",
    color: theme.palette.text.primary,
  },
})

ContactsBlock = withStyles(styles)(ContactsBlock)

export { ContactsBlock }
