import React from "react"
import { Navigation } from "./navigation"
import { ColoredLogo } from "../svg/colored-logo"
import { useTheme } from "@material-ui/core/styles"
import { withStyles } from "@material-ui/core"
import { TelegramLink } from "../svg/telegram-link"
import { LinkedinLink } from "../svg/linkedin-link"
import { TwitterLink } from "../svg/twitter-link"

function Footer({ classes }) {
  const theme = useTheme();
  const currentYear = new Date().getFullYear();

  return (
    <footer className={classes.container}>
      <div className={classes.navAndContactsWrapper}>
        <Navigation linkColor={theme.palette.text.secondary} isHeader={false} />
        <div className={classes.info}>
          <div className={classes.contacts}>
            <a href="tel:+13022615351" className={classes.contactItem}>
              +1-302-261-5351
            </a>
            <a
              href="mailto:team@astraloadhq.com"
              className={classes.contactItem}
            >
              team@astraloadhq.com
            </a>
          </div>
          <div className={classes.address}>
            919 North Market Street, Suite 950, Wilmington, New Castle, 19801
          </div>
        </div>

        <div className={classes.footerLogo}>
          <ColoredLogo />
        </div>
      </div>

      <div className={classes.footerBottom}>
        <div className={classes.copy}>
          © {currentYear} Astraload, Inc. All rights reserved.
        </div>

        <div className={classes.social}>
          <TelegramLink
            className={classes.socialIcon}
            color={theme.palette.text.primary}
          />

          <LinkedinLink
            className={classes.socialIcon}
            color={theme.palette.text.primary}
          />

          <TwitterLink
            className={classes.socialIcon}
            color={theme.palette.text.primary}
          />
        </div>
      </div>
    </footer>
  )
}

const styles = theme => {
  const infoText = {
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "24px",
    color: theme.palette.text.primary,
    textDecoration: "none",
  }

  return {
    container: {
      boxSizing: "border-box",
      padding: "30px 40px 0",
      width: "100%",
      backgroundColor: theme.palette.text.white,

      [theme.breakpoints.down("xs")]: {
        padding: "0 20px",
      },
    },
    navAndContactsWrapper: {
      position: "relative",
      display: "flex",
      marginBottom: "30px",
      alignItems: "flex-end",
      justifyContent: "space-between",

      [theme.breakpoints.down("md")]: {
        marginTop: "40px",
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    info: {
      display: "flex",
      margin: "0 70px 0 80px",
      alignItems: "flex-end",

      [theme.breakpoints.down("md")]: {
        paddingTop: "28px",
        margin: 0,
        flexDirection: "row-reverse"
      },
      [`@media screen and (max-width: 767px)`]: {
        flexDirection: "column-reverse",
        margin: 0,
        alignItems: "flex-start",
      },
    },
    address: {
      ...infoText,

      [theme.breakpoints.down("md")]: {
        marginRight: 50
      },
    },
    contacts: {


      [`@media screen and (max-width: 767px)`]: {
        marginLeft: "0px",
        marginTop: "30px",
      },
    },
    contactItem: {
      display: "inline-block",
      marginRight: 50,
      ...infoText,
    },
    footerLogo: {
      display: "flex",
      alignSelf: "flex-start",

      "& > svg": {
        width: 29,
        height: 48,
      },
      [theme.breakpoints.down("md")]: {
        position: "absolute",
        right: 0,
        bottom: 0,
      },
      [`@media screen and (max-width: 767px)`]: {
        position: "initial",
        marginBottom: "40px",
        order: -1,

        "& > svg": {
          width: "48px",
          height: "81px",
        },
      },
    },
    copy: {
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "24px",
      textAlign: "center",

      [`@media screen and (max-width: 767px)`]: {
        textAlign: "left",
      },

      [theme.breakpoints.down("xs")]: {
        marginBottom: 20
      },
    },
    social: {
      display: "flex",
    },
    socialIcon: {
      display: "flex",
      alignItems: "center",
      marginRight: "20px",
      padding: "7px",
      borderRadius: "8px",
      transition: "background .1s linear",

      "&:hover": {
        background:  "rgba(16, 10, 35, 0.1)",
      },
      cursor: "pointer",
    },
    footerBottom: {
      display: "flex",
      justifyContent: "space-between",
      borderTop: "1px solid #CCCCCC",
      padding: "35px 0 50px 0",
      color: theme.palette.text.primary,

      [theme.breakpoints.down("xs")]: {
        display: "block",
        padding: "30px 0"
      },
    }
  }
}

// eslint-disable-next-line
Footer = withStyles(styles)(Footer)

export { Footer }
